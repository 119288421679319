<template>
  <div>
    <s-toolbar
      label="T-Registro Altas"
      dark
      color="#8E8F91"
      searchInput
      @textSearch="textSearch($event)"
      excel
      @excel="excel()"
    >
      <template v-slot:options>
        <v-tooltip bottom="">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              v-on="on"
              elevation="0"
              @click="downloadBothFiles()"
            >
              <v-icon style="font-size: 16px">mdi-folder-outline</v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>
      </template>
    </s-toolbar>

    <v-container style="background-color: white">
      <v-row>
        <v-col>
          <s-date label="Fecha Inicio" v-model="filter.AgtBeginDate"></s-date>
        </v-col>
        <v-col>
          <s-date label="Fecha Fin" v-model="filter.AgtEndDate"></s-date>
        </v-col>
        <v-col>
          <s-select-definition
            label="Estado"
            :def="1479"
            v-model="filter.AgtStatus"
            clearable
          ></s-select-definition>
        </v-col>
        <v-col>
          <s-select-definition
            label="Cat. Ocupacional"
            :def="1039"
            :dgr="4"
            v-model="filter.TypeOccupationalCategory"
            clearable
          ></s-select-definition>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      v-model="selected"
      dense
      fixed-header
      :items="items"
      :headers="config.headers"
      show-select
      item-key="AgtID"
    >
      <template v-slot:item.Status="{ item }">
        <v-chip
          x-small
          black
          :color="item.CtrStatus == 1 ? 'success' : 'warning'"
        >
          {{ item.HmnEstado }}
        </v-chip>
      </template>
      <template v-slot:item.DisProDep="{ item }">
        {{ item.DisName }} - {{ item.ProName }} - {{ item.DepName }}
      </template>
      <template v-slot:item.Action="{ item }">
        <v-btn x-small color="info" @click="TRegisterItem(item)">
          <v-icon>mdi-file-document</v-icon>{{ "Txt" }}
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog persistent v-model="OpenDialog" max-width="270px">
      <v-card>
        <v-card-title>
          <s-toolbar
            label="T-REGISTRO"
            dark
            color="#8E8F91"
            close
            @close="closeDialog()"
          ></s-toolbar>
        </v-card-title>

        <v-card-text>
          <v-row justify="center" class="d-flex justify-center">
            <div v-if="modalType === 'single'" class="col-md-12 text-center">
              <p><strong>Usuario Seleccionado:</strong></p>
              <p v-if="selectedItem">
                {{ selectedItem.PrsDocumentNumber }} -
                {{ selectedItem.NtpFullName }} -
                {{ selectedItem.AgtBeginDateFormat }}
              </p>
            </div>
            <div class="col-md-12 text-center">
              <v-btn @click="downloadZipWorker(selectedItem)">
                <v-icon>mdi-file-document</v-icon>{{ "Descargar .zip" }}
              </v-btn>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _sGenerationTxtService from "@/services/HumanResource/HmnGenerationTxtService.js";
import JSZip from "jszip";
import _constapp from "@/plugins/const";
import CryptoJS from "crypto-js";

export default {
  components: {},
  data() {
    return {
      showEdit: false,
      showAdd: false,
      OpenDialog: false,
      modalType: null,
      selectedItem: null,
      singleSelect: false,
      selected: [],
      items: [],
      filter: {
        PrsID: 0,
        IsAdmin: this.$fun.isAdmin() ? 1 : 0,
      },
      config: {
        headers: [
          { text: "ID", value: "AgtID" },
          { text: "Acción", value: "Action" },
          { text: "N° Doc", value: "PrsDocumentNumber" },

          { text: "Nombres y Apellidos Completos", value: "NtpFullName" },
          { text: "Área", value: "AreName" },
          { text: "Cargo", value: "PstName" },
          { text: "Fecha de Ingreso", value: "AgtAdmissionDateFormat" },
          { text: "Fecha de Inicio", value: "AgtBeginDateFormat" },
        ],
      },
    };
  },
  watch: {
    filter() {},
    "filter.AgtBeginDate": {
      handler() {
        this.load();
      },
    },
    "filter.AgtEndDate": {
      handler() {
        this.load();
      },
    },
    "filter.AgtStatus": {
      handler() {
        this.load();
      },
    },
    "filter.TypeOccupationalCategory": {
      handler() {
        this.load();
      },
    },
  },
  methods: {
    excel() {
      //console.log("excel");
      let filter = {
        draw: 1,
        length: 10,
        filter: {
          ...this.filter,
        },
      };
      _sGenerationTxtService.excel(filter, this.$fun.getUserID()).then((r) => {
        //console.log("txT", r);
        this.$fun.downloadFile(
          r.data,
          this.$const.TypeFile.EXCEL,
          "Reporte de Txt-" + this.$fun.getDateTimeShortDB() + ""
        );
      });
    },
    load() {
      _sGenerationTxtService
        .list(this.filter, this.$fun.getUserID())
        .then((r) => {
          r.data.forEach((item) => {
            if (item.AgtBeginDate) {
              const date = new Date(item.AgtBeginDate);
              item.AgtBeginDateFormat = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getFullYear()
                .toString()
                .padStart(4, "0")}`;
            }
            if (item.AgtEndDate) {
              const date = new Date(item.AgtEndDate);
              item.AgtEndDateFormat = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getFullYear()
                .toString()
                .padStart(4, "0")}`;
            }
            if (item.AgtAdmissionDate) {
              const date = new Date(item.AgtAdmissionDate);
              item.AgtAdmissionDateFormat = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getFullYear()
                .toString()
                .padStart(4, "0")}`;
            }
          });

          r.data.forEach((item) => {
            if (item.NtpBirthDate) {
              const date = new Date(item.NtpBirthDate);
              item.NtpBirthDateFormat = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date
                .getFullYear()
                .toString()
                .padStart(4, "0")}`;
            }
          });
          this.items = r.data;
        });
    },
    textSearch(text) {
      this.filter.Search = text;
      this.load();
    },
    initialize() {},
    rowSelected(item) {
      if (item !== null) {
        if (item.length > 0) {
          this.item = item[0];
        }
      }
    },
    async downloadBothFiles() {
      if (!this.selected || this.selected.length === 0) {
        this.$fun.alert("No hay datos disponibles para descargar", "warning");
        return;
      }

      let errorCount = 0;
      const validEntries = this.selected.filter((item) => {
        let errors = [];
        this.validarDatos(item, errors);
        if (errors.length > 0) {
          errorCount++;
        }
        return errors.length === 0;
      });

      if (validEntries.length > 0 && errorCount === 0) {
        try {
          const zip = new JSZip();
          zip.file("RP_20329725431.cta", this.generateCtaContent(validEntries));
          zip.file("RP_20329725431.est", this.generateEstContent(validEntries));
          zip.file("RP_20329725431.ide", this.generateIdeContent(validEntries));
          zip.file("RP_20329725431.per", this.generatePerContent(validEntries));
          zip.file("RP_20329725431.tra", this.generateTraContent(validEntries));

          const zipBlob = await zip.generateAsync({ type: "blob" });
          this.$fun.saveFileToFolder(zipBlob, "RP_20329725431.zip");

          this.$fun.alert("Archivos generados correctamente", "success");
        } catch (error) {
          console.error("Error al generar el archivo ZIP:", error);
          this.$fun.alert("Error al generar los archivos", "error");
        }
      } else {
        const generateErrorFile = this.generateErrorFile(this.selected);
        if (generateErrorFile !== "") {
          this.$fun.alert("Errores al crear los documentos", "warning");
          const blob = new Blob([generateErrorFile], { type: "text/plain" });
          this.$fun.saveFileToFolder(blob, "RP_20329725431.errors");
        }
      }
    },
    TRegisterItem(item) {
      this.selectedItem = item;
      this.modalType = "single";
      this.OpenDialog = true;
    },
    closeDialog() {
      this.OpenDialog = false;
      this.selectedItem = null;
      this.modalType = null;
    },

    downloadZipWorker(item) {
      if (item) {
        //console.log("Downloading files...", item);
        const zip = new JSZip();
        const validEntries = [item];
        const generateErrorFile = this.generateErrorFile(validEntries);
        if (generateErrorFile !== "") {
          this.$fun.alert("Errores al crear los documentos", "warning");
          zip.file("RP_20329725431.errors", generateErrorFile);
        }
        zip.file("RP_20329725431.cta", this.generateCtaContent([item]));
        zip.file("RP_20329725431.est", this.generateEstContent([item]));
        zip.file("RP_20329725431.ide", this.generateIdeContent([item]));
        zip.file("RP_20329725431.per", this.generatePerContent([item]));
        zip.file("RP_20329725431.tra", this.generateTraContent([item]));

        zip.generateAsync({ type: "blob" }).then((content) => {
          this.$fun.saveFileToFolder(content, "RP_20329725431.zip");
        });
      }
    },
    // Función que genera el contenido de RP_20329725431.cta
    generateCtaContent(rowSelected) {
      return rowSelected
        .map((item) => {
          const data = this.generateCtaData(item) ?? null;

          return data ? this.$fun.generateFileContent(data, 5) : null;
        })
        .filter((line) => line !== null)
        .join("\n");
    },

    // Para RP_20329725431.est
    generateEstContent(rowSelected) {
      return rowSelected
        .map((item) => {
          const data = this.generateEstData(item) ?? null;
          return data ? this.$fun.generateFileContent(data, 5) : null;
        })
        .filter((line) => line !== null)
        .join("\n");
    },

    // Para el archivo IDE
    generateIdeContent(rowSelected) {
      return rowSelected
        .map((item) => {
          const data = this.generateIdeData(item) ?? null;
          // Si data es válida, genera el contenido; si no, devuelve null
          return data ? this.$fun.generateFileContent(data, 41) : null;
        })
        .filter((line) => line !== null)
        .join("\n");
    },

    // Para el archivo PER: se generan 4 líneas por cada ítem, según el valor de typeRegister (1 a 4).
    generatePerContent(rowSelected) {
      return rowSelected
        .flatMap((item) => {
          return [1, 2, 3, 4].map((typeRegister) => {
            const data = this.generatePerData(item, typeRegister) ?? null;
            // Se agrega un pipe adicional al final para replicar el "||" del código original
            return data ? this.$fun.generateFileContent(data, 8) + "|" : null;
          });
        })
        .filter((line) => line !== null)
        .join("\n");
    },

    // Para el archivo TRA
    generateTraContent(rowSelected) {
      return rowSelected
        .map((item) => {
          const data = this.generateTraData(item) ?? null;
          return data ? this.$fun.generateFileContent(data, 23) : null;
        })
        .filter((line) => line !== null)
        .join("\n");
    },
    generateErrorFile(rowSelected) {
      return rowSelected
        .map((item) => {
          let errors = []; // Usamos un array local en cada iteración
          this.validarDatos(item, errors);
          return errors.length
            ? `Para el DNI: ${item.PrsDocumentNumber}\n${errors.join("")}`
            : null;
        })
        .filter(Boolean)
        .join("\n");
    },
    /* ============================
   Funciones para extraer datos
   ============================ */
    // Para RP_20329725431.cta
    generateCtaData(item) {
      const documentType = item.CodDocument ? item.CodDocument.trim() : "";
      const documentNumber = item.PrsDocumentNumber
        ? item.PrsDocumentNumber.trim()
        : "";
      // Si el tipo de documento es "01", "04" u "11", se asigna "604" al país de emisión
      const issuingCountry = item.CodCountry ?? "";
      const financialEntityCode = item.CodEntityBank
        ? item.CodEntityBank.trim()
        : "";
      const remunerationAccount = item.BatAccountNumber
        ? item.BatAccountNumber.trim()
        : "";

      return {
        0: documentType,
        1: documentNumber,
        2: issuingCountry,
        3: financialEntityCode,
        4: remunerationAccount,
      };
    },

    // Para RP_20329725431.est
    generateEstData(item) {
      const documentType = item.CodDocument ? item.CodDocument.trim() : "";
      const documentNumber = item.PrsDocumentNumber
        ? item.PrsDocumentNumber.trim()
        : "";
      const issuingCountry = item.CodCountry ?? "";
      const companyRuc = "20329725431";
      const establishmentCode = "0000";

      return {
        0: documentType,
        1: documentNumber,
        2: issuingCountry,
        3: companyRuc,
        4: establishmentCode,
      };
    },

    // Para el archivo IDE
    generateIdeData(item) {
      return {
        0: item.CodDocument?.trim() || "",
        1: item.PrsDocumentNumber?.trim() || "",
        2: item.CodCountry ?? "",
        3: item.NtpBirthDateFormat?.trim() || "",
        4: item.NtpPaternalSurname?.trim() || "",
        5: item.NtpMaternalSurname?.trim() || "",
        6: item.NtpName?.trim() || "",
        7: item.TypeSexName || "",
        8: item.CodNational || "",
        10: item.PpnNumberPhoneName?.trim() || "",
        11: item.PemEmailName?.trim() || "",
        // direccion recortar a 20 caracteres
        13: item.PadAddress?.trim().substring(0, 20) || "",
        25: item.PadCodeUbigeo?.trim() || "",
        40: "1",
      };
    },

    // Para el archivo PER
    // Dado que en PER se generan 4 líneas por ítem (para typeRegister 1,2,3 y 4),
    // se crea una función que recibe además el valor "typeRegister"
    generatePerData(item, typeRegister) {
      const doc = item.CodDocument?.trim() || "";
      const docNum = item.PrsDocumentNumber?.trim() || "";
      const issuing = item.CodCountry ?? "";
      const typeCategory = item.TypeCategory || "";
      const beginDate = item.AgtBeginDateFormat?.trim() || "";
      const endDate = ""; //no se pone porque es alta de trabajador
      let deacuerdoatypecategory = "";

      // Según el valor de typeRegister se asigna el valor correspondiente
      if (typeRegister === 2) {
        deacuerdoatypecategory = item.CodTypeWorker || "";
      } else if (typeRegister === 3) {
        deacuerdoatypecategory = item.CodTypeInsurance || "";
      } else if (typeRegister === 4) {
        deacuerdoatypecategory = item.CodTypePension || "";
      }

      return {
        0: doc,
        1: docNum,
        2: issuing,
        3: typeCategory,
        4: typeRegister.toString(),
        5: beginDate,
        6: endDate,
        7: deacuerdoatypecategory,
      };
    },
    // Para el archivo TRA
    generateTraData(item) {
      return {
        0: item.CodDocument?.trim() || "",
        1: item.PrsDocumentNumber?.trim() || "",
        3: item.CodTypeRegimeLabor?.trim() || "",
        //ocupacion debe ser maximo el numero 12 4: item.CodEducationSituation?.trim() || "",
        4: item.CodSunatOccupation > 12 ? "12" : item.CodSunatOccupation || "",
        
        5: item.CodSunatOccupation || "",
        6: item.CodDisability == "1" ? "1" : "0",
        7: item.CodCUSPP?.trim() || "",
        9: item.CodTypeContrat?.trim() || "",
        10: "0", //siempre 0 porque no se usa
        11: item.MaximunDay?.trim() || "0", //jornada maxima de trabajo
        12: item.CodTypeTurn?.trim() || "", //turno
        13: item.Syndical?.trim() || "0", //sindicalizado
        14: item.CodTypeRemuneration?.trim() || "", //tipo de remuneracion
        15: "0.00",
        16: item.SituationWorker || "", //situacion del trabajador
        17: "0",
        18: "0",
        19: "2",
        20: item.CodTypeCategoryWorker?.trim() || "",
        21: "0",
      };
    },
    validarDatos(item, errors) {
      if (item.CodDocument == null) {
        errors.push(`  •El tipo de documento no puede ser nulo\n`);
      }
      if (item.PrsDocumentNumber == null) {
        errors.push(`  •El número de documento no puede ser nulo\n`);
      }
      //para el tipo de documento 04 y 24 el pais no debe ser nullo y diferente de 604
      if (item.CodDocument == "04" || item.CodDocument == "24") {
        if (item.CodCountry == null || item.CodCountry == "604") {
          errors.push(`  •El país no puede ser nulo o igual a 604\n`);
        }
      } else {
        item.CodCountry = "";
      }
      if (item.NtpName == null) {
        errors.push(`  •El nombre no puede ser nulo\n`);
      }
      if (item.NtpPaternalSurname == null) {
        errors.push(`  •El apellido paterno no puede ser nulo\n`);
      }
      if (item.NtpMaternalSurname == null) {
        errors.push(`  •El apellido materno no puede ser nulo\n`);
      }
      if (item.TypeSexName == null) {
        errors.push(`  •El sexo no puede ser nulo\n`);
      }
      if (item.PadAddress == null) {
        errors.push(`  •La dirección no puede ser nulo\n`);
      }
      if (item.PadCodeUbigeo == null) {
        errors.push(`  •El ubigeo no puede ser nulo\n`);
      }
      if (!this.$fun.validateAge(item.NtpBirthDate)) {
        errors.push(`  •Debe tener entre 18 y 65 años\n`);
      }
      if (!this.$fun.isValidEmail(item.PemEmailName)) {
        errors.push(`  •Debe tener un correo válido\n`);
      }
      if (!this.$fun.isValidPhone(item.PpnNumberPhoneName)) {
        errors.push(`  •Debe tener un teléfono válido\n`);
      }
      if (item.CodEntityBank == null) {
        errors.push(`  •El código de entidad bancaria no puede ser nulo\n`);
      }
      if (item.BatAccountNumber == null) {
        errors.push(`  •La cuenta bancaria no puede ser nula\n`);
      }
      if (item.CodTypeInsurance == null) {
        errors.push(`  •El tipo de seguro no puede ser nulo\n`);
      }
      if (item.CodTypePension == null) {
        errors.push(`  •El tipo de pensión no puede ser nulo\n`);
      }
      if (item.CodDocument.length != 2) {
        errors.push(`  •El tipo de documento debe ser de dos cifras\n`);
      }
      if (item.AgtBeginDate == null) {
        errors.push(`  •La fecha de inicio no puede ser nula\n`);
      } /* else{
        //fecha actual - 4 dias
        let fecha = new Date();
        fecha.setDate(fecha.getDate());
        //solo fecha hora 00:00:00
        fecha.setHours(0, 0, 0, 0);
        let fechaInicio = new Date(item.AgtBeginDate);
        if (fechaInicio < fecha) {
          errors.push(`  •La fecha de inicio debe ser igual a la actual\n`);
        }
      } */
      if (item.CodTypeRegimeLabor == null) {
        errors.push(`  •El tipo de régimen laboral no puede ser nulo\n`);
      } else {
        if (item.CodTypeRegimeLabor.length != 2) {
          errors.push(`  •El tipo de régimen laboral debe ser de dos cifras\n`);
        }

        if (item.CodTypeRegimeLabor == "26") {
          //console.log(item.CodTypeWorker);

          //para este regimen el CodTypeWorker debe ser 76 o 48 no admite otro
          if (item.CodTypeWorker != "76" && item.CodTypeWorker != "48") {
            errors.push(
              `   •Para el régimen laboral 26 (RÉGIMEN LABORAL AGRARIO LEY N° 31110) el tipo de trabajador debe ser 76(AGRARIO LEY N° 31110) o 48(AGROINDUSTRIAL)\n`
            );
          }
        }
      }
      if (item.AgtEndDate == null) {
        errors.push(`  •La fecha de fin no puede ser nula\n`);
      }
      if (item.CodTypeRemuneration == null) {
        errors.push(`  •El tipo de remuneracion no puede ser nulo\n`);
      }
    },
    decryptSalary(salary) {
      const secretKey = _constapp._cKey; 
      const bytes = CryptoJS.AES.decrypt(salary, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8); 
    },
  },
  created() {
    this.initialize();
  },
  mounted() {
    this.load();
    this.filter.PrsID = this.$fun.getUserInfo().PrsID;
  },
};
</script>

<style>
.scrollable {
  max-height: 450px; /* Ajusta según sea necesario */
  overflow-y: auto; /* Habilita el scroll vertical */
}
</style>