import Service from "../Service";
const resource = "HmnGenerateTxt/";

export default {

    list(parameters,requestID) {
        return Service.post(resource + "list", parameters, {
            params: { requestID: requestID },
        });
    },

    excel(dtr, requestID) {
        console.log(dtr);
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },

    uploadZip(payload, requestID) {
        return Service.post(resource + "upload-zip", payload, {
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    }
};